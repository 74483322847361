import { Component, OnInit } from '@angular/core';
import {
  NDDClientInfoServiceImpl,
  ThemeService,
  Web3Service
} from './services';
import { BaseComponent } from './views/layouts/base.component';
import { ScannerIcon } from './model/svg-icons.model';
import { NzIconService } from 'ng-zorro-antd/icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent extends BaseComponent implements OnInit {
  constructor(
    protected web3Service: Web3Service,
    protected themeService: ThemeService,
    private iconService: NzIconService,
    protected clientInfoServiceImpl?: NDDClientInfoServiceImpl
  ) {
    super(web3Service, themeService, clientInfoServiceImpl);
    this.initIcons();
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.closeSplash();
  }

  initIcons() {
    ScannerIcon.forEach((icon) => {
      this.iconService.addIconLiteral(icon.name, icon.icon);
    });
  }
}
